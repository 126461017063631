import request from "@/utils/request";
import financeApi from '@/const/apiUrl/finance';
import method from "@/const/request/requestMethod";
import contentType from "@/const/request/requestHeader";
//财务列表接口
export function monthBillList(params) {
    return request({
        url: financeApi.monthBillList,
        method: method.GET,
        data: params,
    })
}
//财务列表详情
export function monthBillDetail(params) {
    return request({
        url: financeApi.monthBillDetail,
        method: method.GET,
        data: params,
    })
}
//结算明细
export function settlementDetail(params) {
    return request({
        url: financeApi.settlementDetail,
        method: method.GET,
        data: params,
       
    })
}
//结算明细详情
export function orderDetail(params) {
    return request({
        url: financeApi.orderDetail,
        method: method.GET,
        data: params,
    })
}


//提现记录
export function cashOutList(params) {
    return request({
        url: financeApi.cashOutList,
        method: method.GET,
        data: params,
    })
}

//平台提现接口
export function selfCashOut(params) {
    return request({
        url: financeApi.selfCashOut,
        method: method.POST,
        data: params,
    })
}

//平台收入信息接口
export function balancePlatform(params) {
    return request({
        url: financeApi.balancePlatform,
        method: method.GET,
        data: params,
    })
}


