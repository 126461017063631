<template>
  <!-- DB商品维护 -->
  <div class="container"  style="margin-top: 15px;">
    <div class="dataOper">
      <div class="OperInput">
        <div class="head">
          <div class="search_box">
            <div class="search_main">
              <div class="status" style="width: 456px;">
                <div>月份查询</div>
                <el-date-picker v-model="datatime" type="month" format="yyyy-MM" @change="datebtn"
                  :clearable="false">
                </el-date-picker>
                <el-button type="primary"  @click="withdraw">提现</el-button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <el-card class="body">
      <div class="dataTable">
        <el-table style="font-family: PingFangRoutine" ref="multipleTable" :data="tableData"
          :header-cell-style="tableHeaderColor" :cell-style="{ color: 'green', fontSize: '14px' }">
          <el-table-column prop="billDate" label="日期">
            <template slot-scope="slot">
              <span style="color:black">{{billDate(slot.row.billDate)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="平台收入 (元)">
            <template slot-scope="scope">
              <span>{{scope.row.platformIncome}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="orderAmount" label="订单金额 (元)"></el-table-column>
          <el-table-column prop="storeIncome" label="销售商品收入 (元)"></el-table-column>
          <el-table-column prop="freightIncome" label="运费收入 (元)"></el-table-column>
          <el-table-column prop="costAmount" label="成本结算金额 (元)">
            <template slot-scope="scope">
              <span style="color:red">{{scope.row.costAmount}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <span @click="set" style="color:blue">查看详情</span>
            </template>
          </el-table-column>
          <el-table-column prop="inventBalance" label="账号余额 (元)">
            <template slot-scope="scope">
              <span style="color:black">{{scope.row.inventBalance==null?'-----':scope.row.inventBalance}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-dialog title="提现" :visible.sync="centerDialogVisible" width="30%" :modal-append-to-body='false'>
      <el-form ref="selfList" :rules="rules" :model="selfList" label-width="80px">
        <el-form-item prop="cashOutType">
          <el-radio-group v-model="selfList.cashOutType">
            <el-radio label="0">自营店</el-radio>
            <el-radio label="1">平台收入</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="cashOutAmount" label="提现金额">
          <el-input v-model="selfList.cashOutAmount" type="input"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selfBtn('selfList')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    monthBillList,
    selfCashOut,
    balancePlatform
  } from "@/api/finance";
  import "@/font-style/font.css";
  export default {
    data() {
      const cashOutAmount = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请填写提现金额'));
        } else if (value <= 0) {
          callback('提现金额不能<=0');
        } else if (value > this.availableTotalIncome) {
          callback(new Error(`最多可提现${this.availableTotalIncome}元`));
        } else {
          callback()
        }
      };
      return {
        centerDialogVisible: false, //提现弹框显示隐藏
        queryinfo: {
          currPage: 1, //当前页码
          startTime: '', //开始时间
          endTime: '', //结束时间
          pageSize: 10, //页面容量
          promoterId: '', //主键id
        },
        availableTotalIncome: '', //可提现总金额
        platformIncome: {}, //平台收入信息
        startTime: "", //input初始显示开始时间
        endTime: "", //input初始显示结束时间
        datatime: [], //日期数组
        total: 0, //总条数
        tableData: [], // 表格数据
        newDate: '',
        selfList: {
          cashOutAmount: 0,
          cashOutAmountStr: 0,
          cashOutType: '0',
        },
        rules: { //正则验证
          cashOutAmount: [{
            validator: cashOutAmount,
            trigger: 'blur'
          }],
        }
      };
    },
    created() {
      this.time()
      this.list()
    },
    methods: {
      //获取当前年月份
      time() {
        let d = new Date()
        let y = d.getFullYear()
        let m = d.getMonth() + 1
        if (m < 10) {
          m = '0' + m
        }
        this.newDate = y + '-' + m + '-' + '01'
        this.datatime = this.newDate
      },
      //数据初始值
      list() {
        //列表数据
        monthBillList({
          date: this.datatime
        }).then(res => {
          this.tableData = res.data
        })
        //平台收入信息
        balancePlatform().then(res => {
          this.platformIncome = res.data
          this.selfList.cashOutAmount = parseFloat(res.data.availableTotalIncomeStr)
          this.availableTotalIncome = parseFloat(res.data.availableTotalIncomeStr)
        })
      },
      withdraw() {
        this.centerDialogVisible = true
        this.selfList.cashOutType = String(this.selfList.cashOutType)
      },
			billDate(str){
				return str.substring(0,7)
			},
      //提现接口
      selfBtn(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
             this.centerDialogVisible = false
            this.selfList.cashOutType = parseFloat(this.selfList.cashOutType)
            selfCashOut({
              cashOutAmount: this.selfList.cashOutAmount * 100,
              cashOutAmountStr: this.selfList.cashOutAmount,
              cashOutType: this.selfList.cashOutType,
            }, ).then(res => {
              if (res.status == 10000) {
                this.$alert(`
              <img style="width:40px;height:40px;margin-bottom:10px;margin-top:-20px" src="../../../success.png"></img>
              <div style="font-wight:bold;font-size:18px;color:black;">提现成功</div>`, {
                  dangerouslyUseHTMLString: true,
                  center: true
                });
              }
            })
            this.selfList.cashOutType = String(this.selfList.cashOutType)
          } else {
            return false;
          }
        });

      },
      //商品记录然后数组格式
      datebtn(e) {
        if (!this.datatime) { // 如果被清空，手动给它赋值为[]
          this.datatime = this.newDate
        }
        var d = new Date(e);
        let youWant = d.getFullYear() + '-' + (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() +1) +'-01'
        this.datatime = youWant
        this.list()
      },
      //表格自定义样式
      tableHeaderColor({
        rowIndex
      }) {
        if (rowIndex === 0) {
          return "background-color: rgba(246, 248, 249, 1);color: #333333;;fontSize: '14px'";
        }
      },
      //点击查看详情跳转
      set() {
        this.$router.push({
          path: '/capital/monthBillDetail',
          query: {
            time: this.datatime
          }
        })
      }
    }
  };
</script>
<style lang="less" scoped>
  @import "../../assets/css/common.less";

/deep/ .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all .3s;
    line-height: 20px;
}
  /deep/ #remark {
    height: 100px;
    width: 260px;
  }

  .el-textarea {
    height: 100px;
  }

  /deep/ .cell {
    text-align: center;
  }

  /deep/ .OperInput {
    display: flex;
    margin-top: -5px;
  }

  /deep/.el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 2px;
    height: 10px;
  }

  .head {
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 21px;
  }

  /deep/ .status .el-input__inner {
    width: 180px;
  }
</style>
