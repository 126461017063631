const financeApi = {
    // 财务列表
    monthBillList: '/capital/monthBillList',
   //月结算详细信息接口
    monthBillDetail:'/capital/monthBillDetail',
    //提现记录接口
    cashOutList:"/storeWithdrawalLog/page",
    //结算明细接口
    settlementDetail:"/order/settlePage",
    //结算明细相关订单详情接口
    orderDetail:"/capital/orderDetail",
    //平台提现接口
    selfCashOut:"/capital/selfCashOut",
    //平台收入信息接口
    balancePlatform:'/capital/balancePlatform'
}


export default financeApi
